import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import DemoStorePage from './pages/DemoPage';  // Example of another page component
import BusinessDetailsPage from './pages/BusinessDetailsPage';  // Example of another page component
import WhatsAppSignupPage from './pages/WhatsAppSignupPage';  // Import the new page component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/demo" element={<DemoStorePage />} />
        <Route path="/demo/business-details" element={<BusinessDetailsPage />} />
        <Route path="/whatsapp-signup" element={<WhatsAppSignupPage />} />  {/* Add the new route */}
      </Routes>
    </Router>
  );
}

export default App;